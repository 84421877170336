import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/layouts/header';
import Service from '../components/services/service';
import { Grid, Theme, createStyles, makeStyles, LinearProgress } from '@material-ui/core';
import { IService } from '../utils/interfaces';
import { getServices } from '../utils/functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        otherProjects: {
            marginTop: theme.spacing(5)
        }
    })
);

const Services = () => {
    const classes = useStyles();
    const [services, setServices] = useState<Array<IService>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                setServices(await getServices());
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, []);

    return (
        <Layout page='Servicios'>
            <SEO title='Servicios' />

            <Header siteTitle='Servicios' />

            {loading ? <LinearProgress /> : 
            <main>
                <div className={classes.otherProjects}>
                    <Grid container spacing={5}>
                        {services.map(service => (
                            <Grid key={service._id} item xs={12} md={6}>
                                <Service service={{ description: <div>{service.description}</div>, name: service.name, }} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </main>
            }

        </Layout>
    );
};

export default Services;